<template>
  <layout width="540">
    <template slot="header">
      {{ title }}
    </template>
    <template>
      <v-form>
        <v-select
          v-model="state.sourceId"
          label="Source name"
          item-value="id"
          item-text="name"
          :items="sources"
          clearable
          @click:clear="clear({ sourceId: '', rpc: null, params: {} })"
        />
        <v-select
          v-model="state.rpc"
          label="Control name"
          item-value="rpc"
          item-text="name"
          :items="controls"
          clearable
          @click:clear="clear({ rpc: null, params: {} })"
        />
        <v-text-field
          v-for="param in params"
          :key="param"
          v-model="state.params[param]"
          :label="`${param} (Default)`"
          clearable
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import {
  ref,
  onMounted,
  nextTick,
  watch,
  computed
} from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { objectPropertyService, objectService } from '@/modules/common/api';
import {
  linkedObjectService,
  linkedObjectsService
} from '@/modules/linked-objects/api';
import { getRpcArguments, prepareObject } from '@/provider/utils';
import { abcSort } from '@/compositions/sortBy';
export default {
  name: 'ManagementItemEdit',
  components: {
    Layout
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    managementItemId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = ref({
      sourceId: '',
      rpc: '',
      params: {}
    });
    const sources = ref([]);
    const controls = ref([]);
    const allControls = ref([]);
    const managementItem = ref({});
    const title = 'Edit management item';

    const handleSubmit = async () => {
      // set properties
      await objectPropertyService.update(
        managementItem.value.stateControlRpc.id,
        {
          value: state.value
        }
      );
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    watch(
      () => state.value.sourceId,
      async sourceId => {
        const source = sources.value.find(source => source.id === sourceId);
        if (source) {
          allControls.value = await linkedObjectService.fetchControlsByObjectId(
            source.id
          );
          allControls.value = allControls.value?.schema?.schemaControls || [];
          controls.value = allControls.value
            .filter(({ isRpc }) => !!isRpc)
            .map(({ rpc, description }) => ({
              name: description || rpc,
              rpc
            }))
            .sort((a, b) => abcSort(a?.name, b?.name));
        }
      }
    );

    const params = computed(() => {
      return getRpcArguments(allControls.value || [], state.value.rpc);
    });

    const clear = obj => {
      nextTick(() => {
        state.value = {
          ...state.value,
          ...obj
        };
        if (!state.value.sourceId) {
          controls.value = [];
          allControls.value = [];
        }
      });
    };

    onMounted(async () => {
      sources.value = await linkedObjectsService.fetch(props.objectId);

      managementItem.value = prepareObject(
        await objectService.fetch(props.managementItemId)
      );

      const {
        sourceId = '',
        rpc = '',
        params = {}
      } = managementItem.value?.stateControlRpc?.value;

      if (sourceId) {
        const source = sources.value.find(source => source.id === sourceId);
        if (source) {
          state.value = {
            sourceId,
            rpc,
            params
          };
        }
      }
    });

    return {
      state,
      sources,
      controls,
      params,
      loading,
      submit,
      clear,
      title
    };
  }
};
</script>
